import React from 'react'
import { Link } from 'gatsby'
import Layout from '@/layout'
import { Metadata } from '@/components/Metadata'
import { RichText } from '@/components/RichText'
import Error from '@/templates/Error'

const NotFoundPage = () => {
  const title = 'Page Not found'

  return (
    <Layout hideFooter>
      <Metadata title={title} />
      <Error heading={title}>
        <RichText>
          <p>
            If you entered a web address please check it was correct, <br />
            or <Link to="/">click here</Link> to go to the homepage.
          </p>
        </RichText>
      </Error>
    </Layout>
  )
}

export default NotFoundPage

import React from 'react'
import PropTypes from 'prop-types'
import * as styles from './Error.module.scss'

const Error = ({ heading, children }) => {
  return (
    <div className={styles.Error}>
      <div className={styles.container}>
        <h1 className={styles.heading}>{heading}</h1>
        <div className={styles.message}>{children}</div>
      </div>
    </div>
  )
}

Error.propTypes = {
  heading: PropTypes.string,
  children: PropTypes.node,
}

export default Error
